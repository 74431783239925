import { motion } from "framer-motion";
import dynamic from "next/dynamic";
import { FC, useState, useEffect, useCallback } from "react";
import useConstants from "../hooks/useConstants";
import imageNorthernLight from "../public/general/backgroundnorthernlight.webp";
import Typography from "./utilities/Typography";
import { useScreen } from "usehooks-ts";
import { useMemo } from "react";

const Star = dynamic(() => import("./Star"), { ssr: false });

const NightSky: FC = () => {
  const { companyName, slogan } = useConstants();
  const screen = useScreen({ initializeWithValue: false });
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  const starCount = useMemo(
    () => (screen ? (screen?.height * screen?.width) / 20000 : 100),
    [screen],
  );

  const handleMouseMove = useCallback((event: MouseEvent) => {
    const windowHeight = (window.innerHeight / 100) * 70;

    setMousePosition({
      x: event.clientX - window.innerWidth / 2,
      y: event.clientY - windowHeight / 2,
    });
  }, []);

  useEffect(() => {
    window.addEventListener("mousemove", handleMouseMove);
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, [handleMouseMove]);

  return (
    <div className="relative flex min-h-[70vh] w-full items-center justify-center overflow-hidden after:pointer-events-none after:absolute after:-bottom-px after:-left-px after:-right-px after:border after:border-l-[50vw] after:border-r-[50vw] after:border-t-[7.5vw] after:border-white after:border-t-[transparent]">
      <motion.span
        initial={false}
        animate={{ x: mousePosition.x / 20, y: mousePosition.y / 40 }}
        transition={{ duration: 0 }}
        className="absolute top-0 bottom-0 left-0 right-0 h-full w-full"
      >
        {Array.from({ length: starCount }).map((_, i) => (
          <Star key={i} />
        ))}
      </motion.span>

      <motion.span
        initial={false}
        animate={{
          x: (mousePosition.x / 30) * -1,
          y: (mousePosition.y / 70) * -1,
          scale: 1.1,
        }}
        transition={{ duration: 0 }}
        className="absolute inset-0 z-[-1] h-full w-full bg-primary bg-cover bg-center bg-no-repeat"
        style={{ backgroundImage: `url(${imageNorthernLight.src})` }}
      />

      <div className="z-40 px-4 py-16 text-center">
        <Typography variant="title" color="text-white">
          {companyName}
        </Typography>

        <Typography
          variant="subtitle"
          color="text-white"
          className="inner-text-size mt-8 border-t border-white pt-8"
          dangerouslySetInnerHTML={{
            __html: slogan.replace(
              "web",
              "<span class='text-5xl sm:text-6xl lg:text-7xl'>Web</span>",
            ),
          }}
        />
      </div>
    </div>
  );
};

export default NightSky;
