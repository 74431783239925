import { NextSeo } from "next-seo";
import Image from "next/image";
import { ReactElement } from "react";
import Banner from "../components/Banner";
import FeatureSection from "../components/FeatureSection";
import NightSky from "../components/NightSky";
import Anchor from "../components/utilities/Anchor";
import Typography from "../components/utilities/Typography";
import useConstants from "../hooks/useConstants";
import DefaultLayout from "../layouts/DefaultLayout";
import GradientLayout from "../layouts/nested/GradientLayout";
import imageProjects from "../public/general/projects-overview.png";
import { NextPageWithLayout } from "./_app";
import {
  faBullhorn,
  faFileCode,
  faPencilRuler,
} from "@fortawesome/free-solid-svg-icons";

const Home: NextPageWithLayout = () => {
  const { companyName, phone, email } = useConstants();

  return (
    <>
      <NextSeo
        description={`${companyName} bouwt op maat gemaakte, snel ladende websites die overtuigen, goed presteren en makkelijk vindbaar zijn. Ontdek onze diensten en projecten!`}
      />

      <NightSky />

      <GradientLayout>
        <div className="align-center container mt-12 mb-16 text-center lg:max-w-screen-md">
          <Typography variant="h2" color="text-primary" className="mb-4">
            Over ons
          </Typography>

          <Typography variant="paragraph">
            Wat doen wij voor onze klanten? Wij bouwen state-of-the-art websites
            op maat. Websites die niet alleen fantastisch eruitzien, maar ook
            supersnel presteren en makkelijk gevonden worden in zoekmachines. Of
            je nu op een desktop, tablet of mobiel kijkt, onze websites passen
            zich naadloos aan, en zorgen ervoor dat jouw onderneming op de
            juiste manier in de kijker komt. Met andere woorden, websites die
            als een perfect jasje zitten en klanten moeiteloos weten te
            overtuigen.
          </Typography>
          <Typography variant="paragraph">
            Kortom: websites waarmee je met een gerust hart kunt jagen op nieuwe
            klanten!
          </Typography>
          <Anchor
            href="/over-ons"
            className="text-lg"
            title={`Lees meer over ${companyName}`}
          >
            Lees meer over {companyName}
          </Anchor>
        </div>

        <FeatureSection
          title="Diensten"
          items={[
            {
              icon: faFileCode,
              title: "Webdevelopment",
              description: `Wil je een website die niet alleen werkt, maar scoort? Een site die makkelijk te vinden is, overtuigt en opvalt? Dan ben je bij ${companyName} aan het juiste adres.`,
              href: "/diensten/webdevelopment",
            },
            {
              icon: faBullhorn,
              title: "Marketing",
              description: `Een geweldige website is één ding, maar deze ook in de markt zetten, dat is een kunst. Bij ${companyName} halen we alles uit jouw website met slimme digitale marketing.`,
              href: "/diensten/marketing",
            },
            {
              icon: faPencilRuler,
              title: "Design",
              description: `Het oog wil ook wat. Of het nu gaat om webdesign, een logo, een complete huisstijl of ander grafisch ontwerp. ${companyName} zorgt voor een creatieve, professionele uitstraling.`,
              href: "/diensten/design",
            },
          ]}
        />

        <Banner
          title="Stuur een e-mail of bel ons, we zijn stiekem best leuk!"
          buttons={[
            {
              href: `mailto:${email}`,
              title: email,
            },
            {
              href: `tel:${phone.html}`,
              title: phone.default,
            },
          ]}
        />

        <div className="container grid justify-center pt-16 sm:grid-cols-2">
          <span className="">
            <Image src={imageProjects} alt="Projecten" />
          </span>
          <div className="inline-flex flex-col items-center justify-center space-y-4 text-center sm:items-start sm:text-left">
            <Typography variant="h2" color="text-primary">
              Projecten
            </Typography>
            <Typography variant="h4" component="h3" color="text-gray">
              Benieuwd wat wij bij {companyName} kunnen?
            </Typography>
            <Anchor
              href="/projecten"
              className="inline-block text-lg sm:self-start"
            >
              Bekijk dan een aantal van onze recente projecten!
            </Anchor>
          </div>
        </div>
      </GradientLayout>
    </>
  );
};

export default Home;

Home.getLayout = function getLayout(page: ReactElement) {
  return <DefaultLayout>{page}</DefaultLayout>;
};
